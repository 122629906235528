import { createRouter, createWebHistory } from "vue-router";
import Login from "@/components/Login.vue";
import MainLayout from "@/components/MainLayout.vue"; // Ensure the correct path
import CryptoJS from "crypto-js";
import db from "@/db";

const routes = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/main",
    component: MainLayout,
    children: [
      {
        path: "map",
        component: () => import("@/views/Map.vue"),
      },
      {
        path: "profile",
        component: () => import("@/views/Profile.vue"),
      },
      {
        path: "report",
        component: () => import("@/views/Report.vue"),
      },
      {
        path: "reportMammal",
        component: () => import("@/views/ReportMammal.vue"),
      },
      {
        path: "reportContainer",
        component: () => import("@/views/ReportContainer.vue"),
      },
      {
        path: "settings",
        component: () => import("@/views/Settings.vue"),
      },
      {
        path: "viewData",
        component: () => import("@/views/ViewData.vue"),
      },
      {
        path: "viewDataLocal",
        component: () => import("@/views/ViewDataLocal.vue"),
      },
      {
        path: "register",
        component: () => import("@/views/Register.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  base: "/",
});

// Global beforeEach guard to check if the user is authenticated
// Checked on every page and if not is redirected to root login
router.beforeEach(async (to, from, next) => {
  if (to.path !== "/" && to.path !== "/main/register") {
    // Protect all routes except the root (login page)
    const sizedFlag = await getLoggedInFlag();

    if (sizedFlag !== true) {
      // If the user is not authenticated, redirect to root (login page)
      return next("/");
    }
  }
  // Allow the navigation
  next();
});

function stringToBoolean(str) {
  return str === "true";
}

async function getLoggedInFlag() {
  try {
    const encryptedFlag = await db.Settings.get(1);
    if (encryptedFlag.Sized) {
      const secretKey = "the--key!";
      const bytes = CryptoJS.AES.decrypt(encryptedFlag.Sized, secretKey);
      const decryptedFlag = bytes.toString(CryptoJS.enc.Utf8);
      return stringToBoolean(decryptedFlag); // Convert to boolean
    }
    return false; // Return false if no flag is found
  } catch (error) {
    console.error("Error fetching the Sized flag:", error);
    return false; // Return false if an error occurs
  }
}

export default router;
