<template>
  <div class="ob-nav-top-bar">
    <div class="ob-menu-container">
      <div class="ob-button ob-button--supressed ob-button--icon">
        <a
          href="#"
          class="headerButton"
          data-bs-toggle="offcanvas"
          data-bs-target="#sidebarPanel"
        >
          <ion-icon
            name="menu-outline"
            style="font-size: 30px; color: black"
          ></ion-icon>
        </a>
      </div>
      <div class="ob-divider"></div>

      <!-- Logo Image -->
      <router-link to="/main/map" class="nav-item" style="margin-left: 10px">
        <img
          :src="`${baseUrl}img/logo.png`"
          alt="image"
          class="imaged"
          style="height: 30px"
        />
      </router-link>

      <!-- Vertical Divider -->
      <div class="vertical-divider"></div>

      <!-- Home Icon -->
      <router-link to="/main/map" class="nav-item">
        <ion-icon
          name="home-outline"
          style="font-size: 24px; margin-right: 10px; color: #3690c4"
        ></ion-icon>
      </router-link>
    </div>
    <div class="ob-menu-container">
      <div class="ob-clock" hidden>14:34</div>
      <div class="ob-divider"></div>
      <!-- <div class="ob-nav-top-bar__apps">
        <a class="ob-app-btn ob-small">
          <div class="ob-btn-icon ob-icon mdi mdi-ferry"></div>
        </a>
      </div>
      <div class="ob-divider"></div>
      <div class="ob-button ob-button--supressed ob-button--icon">
        <span class="ob-icon mdi mdi-bell"></span>
      </div>
      <div class="ob-button ob-button--supressed ob-button--icon">
        <span class="ob-icon mdi mdi-account-circle"></span>
      </div>
      <div
        class="ob-button ob-button--supressed ob-button--icon"
        :class="{ 'dark-mode-active': isDarkMode }"
        @click="toggleDarkMode"
      >
        <span
          class="ob-icon mdi"
          :class="
            isDarkMode ? 'mdi-brightness-7' : 'mdi-brightness-4 mdi-rotate-180'
          "
        ></span>
      </div> 
      <div class="ob-button ob-button--supressed ob-button--icon">
        <span class="ob-icon mdi mdi-apps"></span>
      </div>-->
    </div>
  </div>

  <!-- App Capsule -->
  <div id="appCapsule">
    <div class="section mt-3 mb-3">
      <div class="card">
        <div class="centered-container">
          <div class="custom-hr"></div>
        </div>
        <div class="section full mt-2 mb-2">
          <!--**************************************-->
          <div class="section-title">User Details</div>
          <div class="wide-block pb-1 pt-2">
            <div class="form-group boxed">
              <div class="input-wrapper">
                <label class="form-label" for="name5"
                  >App Version v{{ appVersion }}</label
                >
              </div>
            </div>

            <div class="form-group boxed">
              <div class="input-wrapper">
                <label class="form-label" for="name5">Full Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name5"
                  placeholder="Enter your name"
                  v-model="fullName"
                  autocomplete="off"
                />
                <!-- ... -->
              </div>
            </div>

            <div class="form-group boxed">
              <div class="input-wrapper">
                <label class="form-label" for="email5">E-mail</label>
                <input
                  type="email"
                  class="form-control"
                  id="email5"
                  placeholder="E-mail address"
                  v-model="email"
                  autocomplete="off"
                  disabled
                />
                <!-- ... -->
              </div>
            </div>
          </div>
        </div>

        <div class="centered-container">
          <div class="custom-hr"></div>
        </div>

        <div class="section full mt-2 mb-2">
          <div class="section-title">Display Preferences</div>
          <div class="wide-block pb-1 pt-1">
            <div class="form-group basic">
              <div class="input-wrapper">
                <label class="form-label" for="email1"
                  >Watch Sector Angle (0 - 359)</label
                >
                <input
                  type="number"
                  class="form-control"
                  id="angle"
                  placeholder="Enter Sector Angle (degrees)"
                  v-model="angle"
                  @blur="validateAngle"
                />
                <!-- ... -->
              </div>
            </div>

            <div class="form-group basic">
              <div class="input-wrapper">
                <label class="form-label" for="name1"
                  >No. of Range Rings (3-8)</label
                >
                <input
                  type="number"
                  class="form-control"
                  id="range"
                  placeholder="Enter Number"
                  v-model="range"
                  @blur="validateRange"
                />
                <!-- ... -->
              </div>

              <!--
              <div class="form-group basic">
                <div class="input-wrapper">
                  <label class="form-label" for="name1"
                    >Distance (Meters)</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    id="distance"
                    placeholder="Enter Meters"
                  />               
                </div>                
              </div>
               -->
            </div>
          </div>
        </div>

        <div class="centered-container">
          <div class="custom-hr"></div>
        </div>

        <div class="section full mt-2 mb-2" hidden>
          <div class="section-title">Map</div>
          <div class="wide-block pb-1 pt-1">
            <div class="form-group basic">
              <div class="input-wrapper">
                <label class="form-label" for="name1"
                  >Show User Submitted Reports On Map</label
                >
                <input type="checkbox" v-model="showUserData" />
              </div>
            </div>
          </div>
        </div>

        <div
          class="form-group basic"
          style="display: flex; justify-content: center"
        >
          <button
            id="btnSave"
            class="btn btn-success btn-lg"
            style="width: 40%; max-width: 45%"
            @click="save"
          >
            Save
            <ion-icon name="save-outline" style="margin-left: 20px"></ion-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import db from "@/db";
import { mapState } from "vuex";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  computed: {
    ...mapState({
      settings: state => state.settings,
      // other state properties if needed
    }),
  },
  mounted() {
    this.$store.dispatch("fetchSettings");
  },
  watch: {
    settings: {
      deep: true,
      handler(newSettings) {
        this.fullName = newSettings.Name;
        this.email = newSettings.Email;
        this.range = newSettings.Range;
        this.angle = newSettings.Angle;
        this.selectedUnit = newSettings.ApiEndpoint;
        this.showUserData = newSettings.showUserData;
      },
    },
  },
  data() {
    return {
      fullName: "",
      email: "",
      range: "",
      angle: 360,
      selectedUnit: "meters",
      showUserData: true,
      baseUrl: process.env.VUE_APP_BASE_URL,
      appVersion: process.env.VUE_APP_APP_VERSION,
    };
  },
  methods: {
    validateAngle() {
      // Only apply validation if angle is a number
      if (this.angle) {
        // Apply the min and max limits for angle
        if (this.angle < 0) this.angle = 0;
        if (this.angle > 359) this.angle = 359;
      }
    },
    validateRange() {
      if (this.range) {
        if (this.range < 3) this.range = 3;
        if (this.range > 8) this.range = 8;
      }
    },
    async save() {
      // Prepare the fields that need to be updated
      const updatedFields = {
        SettingId: 1, // Assuming the ID is fixed
        Name: this.fullName,
        Email: this.email,
        Range: this.range,
        Angle: this.angle,
        showUserData: this.showUserData,
        ApiEndpoint: this.apiEndpoint || this.$store.state.settings.ApiEndpoint, // Fallback to existing value
        Token: this.token || this.$store.state.settings.Token, // Fallback to existing value
      };

      try {
        // Fetch the existing settings from the store
        const existingSettings = this.$store.state.settings; // Or fetch from Dexie if needed

        // Merge the existing settings with the updated fields
        const updatedSettings = { ...existingSettings, ...updatedFields };

        // Dispatch the update action with the merged settings
        await this.$store.dispatch("updateSettings", updatedSettings);

        // Show success message
        toast("Settings Saved Successfully.", {
          autoClose: 2000,
          type: "success",
        });

        console.log("Settings saved to the database");
      } catch (error) {
        console.error("Failed to save settings: ", error);
      }
    },
  },
};
</script>
<style scoped>
html {
  font-size: 20px !important;
}
.form-control {
  font-size: 16px;
}
.btn {
  border: 1px solid black;
}
.btn-success {
  background-color: #3b5d80 !important;
}

input[type="checkbox"] {
  width: 24px;
  height: 24px;
  margin-top: 10px;
}

input#email5 {
  color: rgb(167, 165, 165);
  cursor: not-allowed;
}
</style>
